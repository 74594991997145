import React, { useState } from "react";

import Layout from "../components/shared/layout";
import Navbar from "../components/navbar";
import Section from "../components/shared/section";
import styled, { css } from "styled-components";
import { SecondaryPageTitle } from "../components/shared/secondary-page-elements";
import Spinner from "../components/shared/Spinner";
import { motion } from "framer-motion";
import { H5 } from "../components/shared/headings";
import { VisuallyHidden } from "@reach/visually-hidden";
import axios from "axios";

const RedeemPage = () => {
  const [submitting, setSubmitting] = useState(false);
  const [emailValue, setEmailValue] = useState(undefined);
  const [verificationValue, setVerificationValue] = useState(undefined);
  const [message, setMessage] = useState(undefined);
  const [messageTitle, setMessageTitle] = useState(undefined);
  const [isError, setIsError] = useState(undefined);
  const [verificationVisible, setVerificationVisible] = useState(false);

  const handleEmailSubmit = (e) => {
    e.preventDefault();

    setSubmitting(true);

    const request = {email: emailValue}

    if (verificationVisible) {
      if (verificationValue.length !== 6) {
        setMessageTitle("Error");
        setMessage("Please enter a valid verification code");
        setIsError(true);
        return
      }

      request.action = "verify"
      request.verification_code = verificationValue
    } else {
      request.action = "claim"
    }

    axios
      .post("https://us-central1-copilot-internal-tools.cloudfunctions.net/offer_codes",
        request,
        { headers: { "Access-Control-Allow-Origin": "*" }})
      // handle API response
      .then((response) => {
        setSubmitting(false);

        const { status } = response.data;

        if (status === "claimed") {
          setMessageTitle("Check Email");
          setMessage("Check your email for the verification code and enter it here.")
          setVerificationVisible(true);
          setIsError(false);
        } else if (status === "verified") {
          setMessageTitle("Success!");
          setMessage("Check your email for the offer code that you can claim on the iOS App Store")
          setIsError(false);
        }
      })
      // catch any other errors
      .catch((error) => {
        setSubmitting(false);

        const { status } = error.response.data || {};
        if (status === "invalid_email") {
          setMessageTitle("Error");
          setMessage("The email you provided is not valid.");
          setIsError(true);
        } else if (status === "already_claimed_unverified") {
          setVerificationVisible(true);
          setMessageTitle("Verify");
          setMessage("Please enter the verification code already sent to your email.");
          setIsError(false);
        } else if (status === "already_claimed_verified") {
          setMessageTitle("Error");
          setMessage("This email has already claimed an offer code.");
          setIsError(true);
        } else {
          setMessageTitle("Error");
          setMessage("There was an unknown error, please try again later.")
          setIsError(true);
        }
      });
  };

  const renderMessage = () => {
    if (isError) {
      return (
        <FormError>
          <H5>{messageTitle}</H5>
          <p>{message}</p>
        </FormError>
      )
    } else {
      return (
        <FormSuccess>
          <H5>{messageTitle}</H5>
          <p>{message}</p>
        </FormSuccess>
      )
    }
  }

  const renderFormActions = () => (
    <>
      <FormAction>
        <SubmitButton
          type="submit"
          whileHover={{ scale: 1.05 }}
          onClick={handleEmailSubmit}
        >
          {submitting && <Spinner />}
          Submit
        </SubmitButton>
      </FormAction>
    </>
  );

  return (
    <Layout>
       <Navbar/>
        <Section>
          <RedeemTitle>Redeem Company Offer Code</RedeemTitle>
          <RedeemContainer>
            <RedeemForm>
              <H5>Enter your work email address:</H5>
              <Inputs>

                <FormElement>
                  <VisuallyHidden id="email_label">Enter your email address</VisuallyHidden>
                  <input
                    type="text"
                    placeholder="Email address"
                    aria-labelledby="email_label"
                    name="email"
                    id="email"
                    value={emailValue}
                    onChange={e => setEmailValue(e.target.value)}
                    disabled={verificationVisible}
                  />
                </FormElement>

                { verificationVisible &&
                  <FormElement>
                    <VisuallyHidden id="email_label">Enter your email address</VisuallyHidden>
                    <input
                      type="text"
                      placeholder="000000"
                      aria-labelledby="verification_label"
                      name="verification"
                      id="verification"
                      value={verificationValue}
                      onChange={e => setVerificationValue(e.target.value.trim()) }
                    />
                  </FormElement>
                }
              </Inputs>

              { renderFormActions() }
              { !submitting && message !== undefined && renderMessage() }

            </RedeemForm>
          </RedeemContainer>
        </Section>
    </Layout>
  )
}

const RedeemTitle = styled(SecondaryPageTitle)`
  margin-left: auto;
  margin-right: auto;
  text-align: center;
  background: linear-gradient(90deg, #8EBDF9 3.07%, #D2DAE4 100%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
  text-fill-color: transparent;
  grid-column: span 12;
`;

const Inputs = styled.div`
  display: grid;
  width: 100%;
  grid-template-columns: 1fr;
  ${props => props.theme.device.tablet} {
    grid-template-columns: 1fr;
  }
  ${props => props.theme.device.laptop} {
    grid-template-columns: 1fr;
  }
  ${props => props.theme.device.laptopLg} {
    grid-template-columns: 1fr;
  }
  gap: 16px;
`;

const RedeemContainer = styled.div`
  display: flex;
  justify-content: center;
`;

const ButtonStyle = css`
  border: none;
  cursor: pointer;
  background-color: ${(props) => props.theme.color.textPrimary};
  color: ${(props) => props.theme.color.bg};
  display: inline-flex;
  align-items: center;
  justify-content: center;
  white-space: nowrap;
  font-size: 1rem;
  font-family: 'Averta';
  font-weight: 600;
  scale: 1;
  &:focus {
    outline: none;
  }
  &:focus-visible {
    box-shadow: 0 0 0 2pt ${(props) => props.theme.color.bg},
      0 0 0 5pt ${(props) => props.theme.color.textSecondary};
  }
  font-weight: 600;
  padding: 8px 20px;
  border-radius: 5px;
  letter-spacing: -0.01em;
`;

const FormSuccess = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 6px;
  padding: 8px 0;
  color: ${props => props.theme.color.textSecondary};
  & h5 {
    color: ${props => props.theme.color.accentGreen};
  }
`

const FormError = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 6px;
  padding: 8px 0;
  color: ${props => props.theme.color.textSecondary};
  & h5 {
    color: ${props => props.theme.color.accentRed};
  }
`

const FormElement = styled.div`
  & input::placeholder { /* Chrome, Firefox, Opera, Safari 10.1+ */
    color: ${props => props.theme.color.uiPillGround};
  }
  & select {
    background: url("data:image/svg+xml,%3Csvg width='10' height='7' viewBox='0 0 10 7' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M1.12207 2.06065L4.18482 6.37119C4.58347 6.93225 5.41653 6.93225 5.81518 6.37119L8.87793 2.06065C9.34838 1.39854 8.87498 0.481445 8.06275 0.481445H1.93725C1.12502 0.481445 0.651618 1.39854 1.12207 2.06065Z' fill='%236AA7F8'/%3E%3C/svg%3E%0A") no-repeat center right 1rem;
  }
  & input, & select {
    font-size: 1rem;
    line-height: 1.4;
    outline: none;
    border: none;
    padding: 10px 16px;
    border-radius: 0.35rem;
    color: ${props => props.theme.color.uiPillGround};
    border: 1px solid ${props => props.theme.color.uiCardEdge};
    background-color: ${props => props.theme.color.uiCardGround};
    font-family: 'Averta';
    font-weight: 600;
    -webkit-appearance: none;
    -moz-appearance: none;
    width: 100%;
    &:focus {
      border: 1px solid ${props => props.theme.color.textLink};
    }
  }
`;

const FormAction = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
`;

const SubmitButton = styled(motion.button)`
  ${ButtonStyle}
`;

const RedeemForm = styled.form`
  display: flex;
  margin-left: auto;
  margin-right: auto;
  flex-direction: column;
  align-items: center;
  gap: 20px;
  margin: 24px 0;
  ${props => props.theme.device.mobileSm} {
    margin-top: 32px;
    width: 100%;
    margin-left: 20px;
    margin-right: 20px;
  }
  ${props => props.theme.device.tablet} {
    width: 100%;
    max-width: 600px;
  }
  & h5 {
    grid-column: span 4;
  }
`;

export default RedeemPage;
